import type { SocialLinks as SocialLinksType } from "~/types";
import { Icon } from "./ui/icons";
import { spTrackWebInteraction } from "~/utils/tracking";

type Props = {
	socialLink: SocialLinksType;
};

export function SocialLinks({ socialLink }: Props) {
	function handleClick(e: React.MouseEvent<HTMLAnchorElement>) {
		spTrackWebInteraction({
			object: "social media",
			action: "connect",
			value: e.currentTarget.title,
		});
	}

	return (
		<ul className="mt-3 flex items-center gap-4">
			{socialLink?.facebook && (
				<li>
					<a
						href={socialLink?.facebook}
						title="Connect on Facebook"
						onClick={handleClick}
					>
						<Icon name="facebookWithBg" width="20" height="20" />
					</a>
				</li>
			)}

			{socialLink?.twitter && (
				<li>
					<a
						href={socialLink?.twitter}
						title="Connect on Twitter"
						onClick={handleClick}
					>
						<Icon name="twitterWithBg" width="20" height="20" />
					</a>
				</li>
			)}

			{socialLink?.linkedIn && (
				<li>
					<a
						href={socialLink?.linkedIn}
						title="Connect on LinkedIn"
						onClick={handleClick}
					>
						<Icon name="linkedinWithBg" width="20" height="20" />
					</a>
				</li>
			)}

			{socialLink?.github && (
				<li>
					<a
						href={socialLink?.github}
						title="Connect on Github"
						onClick={handleClick}
					>
						<Icon name="githubWithBg" width="20" height="20" />
					</a>
				</li>
			)}

			{socialLink?.mastodon && (
				<li>
					<a
						href={socialLink?.mastodon}
						title="Connect on Mastodon"
						onClick={handleClick}
					>
						<Icon name="mastodonWithBg" width="20" height="20" />
					</a>
				</li>
			)}

			{socialLink?.email && (
				<li>
					<a
						href={`mailto:${socialLink?.email}`}
						title="Connect via email"
						onClick={handleClick}
					>
						<Icon name="envelopeWithBg" width="20" height="20" />
					</a>
				</li>
			)}

			{socialLink?.website && (
				<li>
					<a
						href={`${socialLink?.website}`}
						title="Connect with me: My Personal Website"
						onClick={handleClick}
					>
						<Icon name="website" width="20" height="20" />
					</a>
				</li>
			)}
		</ul>
	);
}
